<template>
	<div>
		<el-form :inline="true" :model="params" size="mini">
			<el-form-item label="关键词">
				<el-input v-model="params.keywords" placeholder="内容关键词"></el-input>
			</el-form-item>
			<el-form-item label="类型">
				<el-select v-model="params.status" placeholder="类型" filterable clearable>
					<el-option v-for="(status, t) in statuses" :key="t" :label="status" :value="t"/>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getDrawrecords(params)">查询</el-button>
				<el-button type="primary" @click="getDrawrecords(params)">刷新</el-button>
				<el-button type="primary" @click="getDrawrecords({})">重置</el-button>
				<el-button type="primary" @click="$utils.toRoute('/luckydraws')" :disabled="!$utils.index('luckydraws')">抽奖列表</el-button>
			</el-form-item>
		</el-form>
		<el-table ref="luckydraws_table" :data="luckydraws" :max-height="theme.height - 250" :size="theme.size">
			<el-table-column fixed="left" type="selection" width="42"/>
			<el-table-column label="图片">
				<el-image slot-scope="scope" class="brand_logo" :src="scope.row.image" :preview-src-list="[scope.row.image]">
					<i slot="error" class="el-icon-picture-outline"></i>
				</el-image>
			</el-table-column>
			<el-table-column prop="lucky_draw.name" label="活动名称"/>
			<el-table-column prop="name" label="中奖名称"/>
			<el-table-column prop="user.username" label="参与用户" width="80"/>
			<el-table-column prop="at" label="操作时间" width="210">
				<template slot-scope="scope">更新：{{scope.row.updated_at}}<br>创建：{{scope.row.created_at}}</template>
			</el-table-column>
			<el-table-column prop="name" label="更多操作" width="100">
				<template slot-scope="scope">
					<el-dropdown>
						<span class="el-dropdown-link">更多操作<i class="el-icon-arrow-down el-icon--right"></i></span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item :disabled="scope.row.status != 'SENDED'"><div @click="onUse(scope.row)">核销</div></el-dropdown-item>
							<el-dropdown-item :disabled="scope.row.status != 'USED'"><div @click="onReturn(scope.row)">退券</div></el-dropdown-item>
							<el-dropdown-item :disabled="scope.row.status != 'SENDED'"><div @click="onDeactivate(scope.row)">失效</div></el-dropdown-item>
							<el-dropdown-item><div @click="onRecords(scope.row)">记录</div></el-dropdown-item>
							<el-dropdown-item><div @click="onDelete(scope.row)">删除</div></el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => getDrawrecords({ ...params, page: 1, perPage: v})"
			@current-change="(v) => getDrawrecords({ ...params, page: v})"
			background/>
		<el-dialog width="480px" :title="coupon.title" :visible.sync="operate_dialog">
			<div class="coupon_card" style="background-color: #F56C6C;" v-if="coupon">
				<p>唯一编码：{{coupon.hidden_code}}</p>
				<p v-if="coupon.coupon">使用门槛：{{coupon.coupon.transaction_minimum/100}} 元</p>
				<p v-if="coupon.coupon">优惠额度：{{coupon.coupon.discount_amount/100}}</p>
				<p v-if="coupon.coupon">使用时间：{{coupon.coupon.available_begin_at}} - {{coupon.coupon.available_end_at}}</p>
				<p v-if="coupon.user">所属用户：{{coupon.user.username}}</p>
				<p class="status">{{coupon.status_name}}</p>
			</div>
			<el-input type="textarea" placeholder="请输入操作备注说明。" v-model="coupon.reason" maxlength="128" show-word-limit></el-input>
			<span slot="footer" class="dialog-footer">
				<el-button @click="operate_dialog = false">取 消</el-button>
				<el-button type="primary" @click="submitCouponRecord(coupon)">确 定</el-button>
			</span>
		</el-dialog>
		<el-drawer title="操作记录" :visible.sync="records_drawer">
			<div style="padding: 0 20px;">
				<div class="coupon_card" style="background-color: #F56C6C;" v-if="coupon">
					<p>唯一编码：{{coupon.hidden_code}}</p>
					<p v-if="coupon.coupon">使用门槛：{{coupon.coupon.transaction_minimum/100}} 元</p>
					<p v-if="coupon.coupon">优惠额度：{{coupon.coupon.discount_amount/100}}</p>
					<p v-if="coupon.coupon">使用时间：{{coupon.coupon.available_begin_at}} - {{coupon.coupon.available_end_at}}</p>
					<p v-if="coupon.user">所属用户：{{coupon.user.username}}</p>
					<p class="status">{{coupon.status_name}}</p>
				</div>
				<el-timeline>
					<el-timeline-item v-for="(record, index) in records" :key="index" :color="record.color" :timestamp="record.created_at">{{record.content}}</el-timeline-item>
				</el-timeline>
			</div>
		</el-drawer>
	</div>
</template>

<style>
	.el-input .el-select .el-input {
		width: 100px;
	}
	.coupon-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.coupon-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.coupon_card {
		color: #fff;
		overflow: hidden;
		line-height: 1.5;
		position: relative;
		padding: 10px 20px;
		margin-bottom: 20px;
	}
	.coupon_card .status {
		width: 100px;
		height: 100px;
		font-size: 24px;
		line-height: 100px;
		border-radius: 50px;
		transform: rotate(25deg);
		color: rgba(255, 255, 255, 0.8);
		background-color: rgba(255, 255, 255, 0.2);
		border: 2px dashed rgba(255, 255, 255, 0.5);
		text-align: center;
		position: absolute;
		top: -15px;
		right: -15px;
	}
	.brand_logo {
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		background-color: #f1f1f1;
	}
</style>

<script>
	import { mapState } from 'vuex';
	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
			onUse (r) {
				r.type = 'use';
				r.title = '核销操作';
				this.coupon = r;
				this.operate_dialog = true;
			},
			onReturn (r) {
				r.type = 'return';
				r.title = '退券操作';
				this.coupon = r;
				this.operate_dialog = true;
			},
			onDeactivate (r) {
				r.type = 'deactivate';
				r.title = '失效操作';
				this.coupon = r;
				this.operate_dialog = true;
			},
			onRecords (r) {
				this.records_drawer = true;
				this.coupon = r;
				this.getOperateRecords(r);
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.name+'】抽奖记录吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_DRAWRECORDS, {params: {action: 'create', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getDrawrecords(this.params);
					this.$message.success(msg);
				})
			},
			async submitCouponRecord (data) {
				const res = await this.$http.post(this.$api.URI_DRAWRECORDS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						if (this.operate_dialog) {
							this.operate_dialog = false;
						}
						this.getDrawrecords(this.params);
					}
				})
			},
			async getOperateRecords (r) {
				const res = await this.$http.get(this.$api.URI_DRAWRECORDS, {params: {action: 'records', user_coupon_id: r.id}, headers: {loading: true}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.records = result;
			},
			async getDrawrecords (params) {
				const res = await this.$http.get(this.$api.URI_DRAWRECORDS, { params });
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.luckydraws = result.data;
				this.statuses = result.status;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				luckydraws: [],
				statuses: [],
				records: [],
				operate_dialog: false,
				records_drawer: false,
				coupon: {},
				params: {
					perPage: 10,
					type: []
				}
			}
		},
		async created () {
			const params = {...this.params, ...this.$route.query};
			this.getDrawrecords(params);
		}
	};
</script>